import React from 'react';
import imgPrivate from "./img/icons/private.svg";
import imgFast from "./img/icons/fast.svg";
import imgSecure from "./img/icons/secure.svg";

function About(props) {
    return (
        <>
        <section className="about">
			<div className="container">
				<h2 className="section-title">About Coinbooth</h2>
				<p className="section-subtext about-subtext">Coinbooth provides the experience of a phone booth in your browser.</p>
				<ul className="features-list">
					<li className="feature">
						<div className="feature-img-block private-img-block">
							<img src={imgPrivate} alt="" className="feature-img"/>
						</div>
						<h3 className="feature-title">Private</h3>
						<p className="feature-text">Like a payphone, no personally identifiable information is required before making a call.</p>
					</li>
					<li className="feature">
						<div className="feature-img-block fast-img-block">
							<img src={imgFast} alt="" className="feature-img"/>
						</div>
						<h3 className="feature-title">Fast</h3>
						<p className="feature-text">Since there is no sign-up process, you can be making calls as soon as you've topped up.</p>
					</li>
					<li className="feature">
						<div className="feature-img-block secure-img-block">
							<img src={imgSecure} alt="" className="feature-img"/>
						</div>
						<h3 className="feature-title">Secure</h3>
						<p className="feature-text">Voice transmission between your browser and the public phone network is encrypted with DTLS-SRTP.</p>
					</li>
				</ul>
			</div>
		</section>

        </>
    );
}

export default About;