
// const dialerViewSlice = createSlice({
//     name: 'visibleView',
//     initialState: "default-view",
//     reducers: {
//         'CALL_NOW': state => "calling-view",
//         'CANCEL': state => "call-ends-view",
//         'NEW_CALL': state => "default-view",
//         'ANSWERED': state => "answered-view",
//         'HANGUP': state => "call-ends-view",
//         'REJECTED': state => "rejected-view"
//     }
// });

// export default dialerViewSlice;


function dialerStateMachineReducer(state, action) {
  let newState;
  switch (action) {
    case 'CALL_NOW': newState = "calling-view"; break;
    case 'CANCEL': newState = "call-ends-view"; break;
    case 'NEW_CALL': newState = "default-view"; break;
    case 'ANSWERED' : newState = "answered-view"; break;
    case 'HANGUP': newState = "call-ends-view"; break;
    case 'REJECTED': newState = "rejected-view";
  }
  return newState;
};

export default dialerStateMachineReducer;