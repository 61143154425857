import React, { useState, useReducer, useEffect, useRef } from 'react';
import phoneBoothImage from './img/illustrations/phone-booth.svg';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { SipProvider } from 'react-sip';
import dialerStateMachine from './dialerStateMachine';

function Dialer() {
    
    const [animated, setAnimated] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
	const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);
	const sipProviderComponentReference = useRef();
	const [currentView, transitionView] = useReducer(dialerStateMachine, "default-view");
	
	useEffect(() => {
		const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
		setPhoneNumberIsValid(parsedPhoneNumber ? parsedPhoneNumber.isValid() : false);
	}, [phoneNumber]);

    return (
		<SipProvider
			host="localhost"
			port={5066}
			// pathname="" // Path in socket URI (e.g. wss://sip.example.com:7443/ws); "" by default
			secure={false}
			user="1000"
			password="coinbooth" // usually required (e.g. from ENV or props)
			autoRegister={false} // true by default, see jssip.UA option register
			autoAnswer={false} // automatically answer incoming calls; false by default
			iceRestart={false} // force ICE session to restart on every WebRTC call; false by default
			sessionTimersExpires={120} // value for Session-Expires header; 120 by default
			extraHeaders={{ // optional sip headers to send
			invite: ['X-Foo: foo2', 'X-Bar: bar2']
			}}
			debug={true} // whether to output events to console; false by default
			ref={sipProviderComponentReference}
		>
			
			<section className="hero" >
				<div className="default-view hero-view" hidden={!(currentView === "default-view")}>
					<div className="container relative">
						<div className={"hero-content animation-target " + (animated ? "animated" : "")}>
							<h1 className="main-title">Call any number, pay with crypto.</h1>
							<div className={"phone-input-wrapper animation-target " + (animated ? "animated" : "")}>
								<label htmlFor="telNo" className="phone-input-label">Enter phone number to start</label>
								<input
									className="phone-input"
									type="tel" 
									name=""
									id="telNo"
									placeholder={animated ? "+ __ ___ ___ ___" : "Enter phone number to start"}
									autoComplete="off"
									onFocus={() => setAnimated(true)}
									onBlur={() => setAnimated(false)}
									value={phoneNumber}
									onClick={() => setPhoneNumber('+447700009196')}
									onChange={event => setPhoneNumber(event.target.value)}/>
								<div className="filled-number-view" hidden>
									<div className="call-rate-info">
										<p className="call-info-text">
											Price per minute:
											<span className="call-info-highlited">Ξ0.0234 ($0.31)</span>
										</p>
									</div>
									<button className="btn btn-primary btn-fullwidth">Fund your account</button>
								</div>
								<div className="call-now-view" hidden={!phoneNumberIsValid}>
									<div className="call-rate-info">
										<p className="call-info-text">
											Price per minute:
											<span className="call-info-highlited">Ξ0.0234 ($0.31)</span>
										</p>
										<p className="call-info-text">
											Minutes left:
											<span className="call-info-highlited">21min</span>
										</p>
									</div>
									<button
										className="btn call-btn btn-fullwidth"
										data-view="calling-view"
										onClick={(event) => {
											sipProviderComponentReference.current.startCall('9196');
											setTimeout(() => {
												if (Math.random() < 0.5)
													transitionView("ANSWERED")
												else
													transitionView("REJECTED");
											}, 1000);
											transitionView("CALL_NOW");
											}
										}>
										<span className="call-btn-text">Call now</span>
									</button>
								</div>
							</div>
						</div>
						<div className={"hero-img-block animation-target " + (animated ? "animated" : "")}>
							<img src={phoneBoothImage} alt="phone booth" className="hero-img"/>
						</div>
					</div>
					<svg className={"triangle animation-target " + (animated ? "animated" : "")} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
						<polygon fill="#FBFDFF" points="0,100 100,0 100,100" />
					</svg>
				</div>
				<div className="answered-view hero-view text-center" hidden={!(currentView === "answered-view")}>
					<div className="container">
						<h1 className="main-title calling-title">Answered</h1>
						<p className="calling-number">+48 600 100 100</p>
						<div className="call-info">
							<p className="call-info-text text-center">
								Time:
								<span className="call-info-highlited">0:54</span>
							</p>
							<p className="call-info-text text-center">
								Minutes left:
								<span className="call-info-highlited">21min</span>
							</p>
						</div>
						<div className="keyboard">
							<div className="keyboard-main">
								<button className="keyboard-btn">1</button>
								<button className="keyboard-btn">2</button>
								<button className="keyboard-btn">3</button>
								<button className="keyboard-btn">4</button>
								<button className="keyboard-btn">5</button>
								<button className="keyboard-btn">6</button>
								<button className="keyboard-btn">7</button>
								<button className="keyboard-btn">8</button>
								<button className="keyboard-btn">9</button>
								<button className="keyboard-btn">*</button>
								<button className="keyboard-btn">0</button>
								<button className="keyboard-btn">#</button>
							</div>
							<div className="keyboard-footer">
								<button
									className="btn keyboard-footer-btn cancel-btn" 
									data-view="call-ends-view"
									onClick={() => transitionView("CANCEL")}>
									<span className="cancel-btn-text">End call</span>
								</button>
								<button className="btn keyboard-footer-btn mute-btn">
									<span className="mute-btn-text">Mute</span>
									<span className="unmute-btn-text">Unmute</span>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="calling-view hero-view" hidden={!(currentView === "calling-view")}>
					<div className="container">
						<h1 className="main-title calling-title">Calling...</h1>
						<p className="calling-number">+48 600 100 100</p>
						<div className="btn-block">
							<button 
								className="btn cancel-btn" 
								data-view="call-ends-view"
								onClick={() => transitionView("CANCEL")}
							>Cancel</button>
						</div>
					</div>
				</div>
				<div className="rejected-view hero-view text-center" hidden={!(currentView === "rejected-view")}>
					<div className="container">
						<h1 className="main-title calling-title">Rejected...</h1>
						<p className="calling-number">+48 600 100 100</p>
						<div className="row justify-content-center btn-block">
							<button 
								className="btn call-btn call-again-btn"
								data-view="calling-view"
								onClick={() => transitionView("CALL_NOW")}>
								<span className="call-btn-text">Call again</span>
							</button>
							<button 
								className="btn btn-secondary another-call-btn" 
								data-view="default-view"
								onClick={() => transitionView("NEW_CALL")}
							>Make another call</button>
						</div>
					</div>
				</div>
				<div className="out-of-funds-view hero-view text-center" hidden={!(currentView === "out-of-funds-view")}>
					<div className="container">
						<h1 className="main-title calling-title">You’re out of funds</h1>
						<p className="calling-number">+48 600 100 100</p>
						<div className="call-info">
							<p className="call-info-text">
								Time:
								<span className="call-info-highlited">0:54</span>
							</p>
							<p className="call-info-text">
								Cost is:
								<span className="call-info-highlited">21min</span>
							</p>
						</div>
						<div className="row justify-content-center">
							<button className="btn btn-primary modal-open" data-modal="fund-modal">Fund account</button>
							<button 
								className="btn btn-secondary back-to-main-btn" 
								data-view="default-view"
								onClick={() => transitionView("NEW_CALL")}>
								Back to main view</button>
						</div>
					</div>
				</div>
				<div className="call-ends-view hero-view text-center" hidden={!(currentView === "call-ends-view")}>
					<div className="container">
						<h1 className="main-title calling-title">Call ends</h1>
						<p className="calling-number">+48 600 100 100</p>
						<div className="call-info">
							<p className="call-info-text">
								Time:
								<span className="call-info-highlited">0:54</span>
							</p>
							<p className="call-info-text">
								Cost is:
								<span className="call-info-highlited">21min</span>
							</p>
						</div>
						<div className="row justify-content-center">
							<button
								className="btn btn-primary"
								data-view="default-view"
								onClick={() => transitionView("NEW_CALL")}>
								Make another call
							</button>
							<button className="btn btn-secondary back-to-main-btn modal-open" data-modal="refund-modal">Refund</button>
						</div>
					</div>
				</div>
			</section>
		</SipProvider>
    );
}



// function mapStateToProps(state) {
//     return {
//         dialerState: {
//             visibleView: state
//         }
//     }
// }

// const mapDispatchToProps = {
//     CALL_NOW: dialerViewSlice.actions.CALL_NOW,
//     ANSWERED: dialerViewSlice.actions.ANSWERED, 
//     REJECTED: dialerViewSlice.actions.REJECTED,
//     NEW_CALL: dialerViewSlice.actions.NEW_CALL,
//     CANCEL: dialerViewSlice.actions.CANCEL,
//     HANGUP: dialerViewSlice.actions.HANGUP
// }


// export { dialerViewSlice };
// export default connect(mapStateToProps, mapDispatchToProps)(Dialer);

export default Dialer;