import React, { useState } from 'react';
import { Provider as FigbirdProvider } from 'figbird';

import io from 'socket.io-client';
import createFeathersClient from '@feathersjs/client';
import socketio from '@feathersjs/socketio-client';
import authClient from '@feathersjs/authentication-client';

import { generate } from 'generate-password-browser';

window.localStorage.debug = "socket*';"
const socket = io('http://localhost:3030');
const client = createFeathersClient();
client.configure(socketio(socket));
client.configure(authClient());

function DebugPanelOuter(props) {
	
	// FeathersJS object will be the state, but we use lazy initialisation.
	// See https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
	const [feathers] = useState(client);
	const [username, setUsername] = useState('none');
	const [authStatus, setAuthStatus] = useState(false);
	const [reAuthStatus, setReAuthStatus] = useState(false);
	
	return (
		<FigbirdProvider feathers={feathers}>
			<section className="debugpanel">
				<div className="container">
					<h3>Debug:</h3>
					<button onClick={() => {
							console.log('Register clicked');
							console.time("Reg");
							const password = generate({length: 16});
							window.localStorage.setItem('coinbooth_password', password);
							console.log(`Random password: ${password}`);
							client.service('accounts').create({password}).then((res) => {
								setUsername(res.username);
								window.localStorage.setItem('coinbooth_username', res.username);
								console.timeEnd("Reg");
							});
						}
					} className="btn-primary">Register</button>
					<div>Username: {username}</div>
					<button onClick={() => {
						client.authenticate({
							strategy: 'local',
							username: window.localStorage.getItem('coinbooth_username'),
							password: window.localStorage.getItem('coinbooth_password')
						})
							.then((data) => {
								console.log(data);
								setAuthStatus(true)
							});
					}}>Authenticate</button>
					<div>Authentication status: {authStatus ? "True" : "False" }</div>
					<button onClick={() => {
						client.authentication.reAuthenticate()
							.then((data) => {
								console.log(data);
								setReAuthStatus(true)
							});
					}}>reAuthenticate</button>
					<div>ReAuthentication status: {reAuthStatus ? "True" : "False" }</div>
				</div>
			</section>
        </FigbirdProvider>
    );
}

export default DebugPanelOuter;