import React from 'react';
import imgCloseIcon from "./img/icons/close-ico.svg";
import QRCode from 'qrcode.react';

function Modals(props) {

    const bchUri = "bitcoincash:" + props.account.data.bch_addresses[0];

    return (
        <>
        <div
            className={"modal-overlay" + (props.modals.topup ? " active" : "")} 
            id="fund-modal"
            onClick={() => props.setModals({... props.modals, topup: false})}
        >
            <div className="modal-body" onClick={(event) => event.stopPropagation()}>
                <button
                    className="modal-close js-close" 
                    onClick={(event) => {props.setModals({... props.modals, topup: false})}}
                >
                    <img src={imgCloseIcon} alt="close"/>
                </button>
                <h3 className="modal-title">Fund your account</h3>
                {/* <p className="or">OR</p> */}
                <div className="send-ether">
                    <h4 className="input-title">Send BCH to:</h4>
                    <QRCode value={bchUri}/>
                    <div className="send-ether-row row justify-content-between">
                        <a className="ether-wallet" href={bchUri} disabled>{bchUri}</a>
                        {/* <button className="qr-btn"></button> */}
                    </div>
                    <div className="hint">Your funds should show immediately after sending. You can get a refund of your change at any time.</div>
                </div>
            </div>
        </div>

        <div className="modal-overlay" id="refund-modal">
            <div className="modal-body">
                <button className="modal-close js-close">
                    <img src={imgCloseIcon} alt="close"/>
                </button>
                <h3 className="modal-title">Refund</h3>
                <p className="min-refund hint">Minimum refund is &Xi;0.012 ($1).</p>
                <div className="">
                    <h4 className="input-title">Refund address</h4>
                    <div className="ether-wallet">0xb72c14dc3643dbc64258e7f3d2ff232df61cb6ce</div>
                </div>
                <div className="modal-btn-row row justify-content-end">
                    <button className="btn-secondary btn modal-btn js-close">Cancel</button>
                    <button className="btn-primary btn modal-btn modal-open" data-modal="refund-success-modal">Refund</button>
                </div>
            </div>
        </div>

        <div className="modal-overlay" id="refund-success-modal">
            <div className="modal-body">
                <button className="modal-close js-close">
                    <img src={imgCloseIcon} alt="close"/>
                </button>
                <h3 className="modal-title">Refund</h3>
                <p className="modal-text">Your funds will be returned to your account shortly.</p>
                <div className="modal-btn-row row justify-content-end">
                    <button className="btn-secondary btn modal-btn js-close">Close</button>
                </div>
            </div>
        </div>   
        </>
    );
}

export default Modals;