import React from 'react';
import { ECPair, ECPairInterface, crypto } from 'bitcoinjs-lib';
import * as secp256k1 from 'tiny-secp256k1';
import shaJs from 'sha.js';
import randomBytes from 'randombytes';

const privateKeyWIF : string = 'KwGm8n9r9HhMm3vBPsZDdcdpxZx9cgK8L812x6PFKmB7PDGWgDh3';
console.log(`Private key WIF: ${privateKeyWIF}`);

const x = randomBytes(32);
console.log(`Random bytes: ${x.toString('hex')}`);

const baseKeyPair : ECPairInterface = ECPair.fromWIF(privateKeyWIF);
const privateKey : Buffer = baseKeyPair.privateKey!;
console.log(`Private key: ${privateKey ? privateKey.toString('hex') : "invalid"}`); // 0191c9966dd31623b85b82297c2f7f320683897ba7c92e2e86f692b5d061da9f
const publicKey : Buffer = baseKeyPair.publicKey;
console.log(`Public key: ${publicKey.toString('hex')}`);  // 03c1c33b8dfa915907a4bc4fcf01edfa838536cb4d39454bc8bcfee703e816fb9e

const something : Buffer = crypto.sha256(Buffer.from("hello"));

const specialPublicKey : Buffer = secp256k1.pointAddScalar(publicKey, x, true)!;
console.log(`Special public key: ${specialPublicKey.toString('hex')}`);

const specialPrivateKey : Buffer = secp256k1.privateAdd(privateKey, x)!;
console.log(`Special private key: ${specialPrivateKey.toString('hex')}`);

console.log(`Rederived special public key: ${secp256k1.pointFromScalar(specialPrivateKey, true)!.toString('hex')}`);

const sig : Buffer = secp256k1.sign(shaJs('sha256').update(Buffer.from("hello")).digest(), specialPrivateKey);

console.log(`Message was ${secp256k1.verify(shaJs('sha256').update(Buffer.from("hello")).digest(), specialPublicKey, sig) ? "verified" : "not verified"}`);


function CryptoTest() {
    return (
        <div>
            "hmmmm"
        </div>
    );
};

export default CryptoTest;